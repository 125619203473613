import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LoginUser } from "./LoginUser";
import { HttpClient } from "@angular/common/http";
import { RoleModulePemission } from "./role-module-permission";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { JSONResponse } from "../../utils/json-response";

const apiUrl = environment.apiUrl;
const getRoleEntitlementUrl = 'role/getRoleEntitlement/'


@Injectable({ providedIn: 'root' })
export class RoleEntitlementService {

    private _roleModulePermission: Subject<void> = new Subject();
    roleModulePermission: Observable<void> = this._roleModulePermission.asObservable();

    constructor(private http: HttpClient) { }

  public getRoleEntitlement(roleId :number) {

    let roleModulePemission:RoleModulePemission[];
    return this.http.get<JSONResponse<RoleModulePemission[]>>(apiUrl + getRoleEntitlementUrl+roleId)
      .subscribe((response) => {
         localStorage.setItem('roleEntitlement',JSON.stringify( response.data) );
         roleModulePemission=response.data;
      });
    
  }
}