export class JSONResponse<T> {
  status: JSONResponseStatus;
  result: T;
  data: T;
}

export enum JSONResponseStatus {
  SUCCESS = 200,
  WARNING = '400',
  EMPTYSET = '700',
  VALIDATIONERROR = '800',
  SERVERERROR='500',
  ERROR = '900',
  AUTHENTICATIONFAILURE = '999'
}
