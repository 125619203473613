import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NbAuthToken } from '../token/token';
import { NbAuthService } from '../auth.service';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '../../auth.options';
import { NbAclService } from '@nebular/security';

@Injectable()
export class NbAuthJWTInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter, protected aclService: NbAclService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      let userData = JSON.parse(localStorage.getItem('userdata'));

      // if (userData != 'undefined' && userData != null) {
      //   let roleName = userData.user.role;
      //   userData.permissions.forEach((permission) => {
      //     let value: string = permission.key;
      //     var permissionEntity = value.split("_", 2);
      //     this.aclService.allow(roleName, permissionEntity[0], permissionEntity[1]);
      //   });

      //   this.aclService.allow(roleName, 'view', 'profile');
      // }

      return this.authService.isAuthenticatedOrRefresh()
        .pipe(
          switchMap(authenticated => {
            if (authenticated) {

              // let userData = JSON.parse(localStorage.getItem('userdata'));
              // let roleName = userData.user.role;
              // console.log(userData);
              // userData.permissions.forEach((permission) => {
              //   let value: string = permission.key;
              //   var permissionEntity = value.split("_", 2);
              //   this.aclService.allow(roleName, permissionEntity[0], permissionEntity[1]);
              // });

              // this.aclService.allow(roleName, 'view', 'profile');

              return this.authService.getToken().pipe(
                switchMap((token: NbAuthToken) => {
                  const JWT = `Bearer ${token.getValue()}`;
                  req = req.clone({
                    setHeaders: {
                      Authorization: JWT,
                    },
                  });
                  return next.handle(req);
                }),
              )
            } else {
              // Request is sent to server without authentication so that the client code
              // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
              return next.handle(req);
            }
          }),
        )
    } else {
      return next.handle(req);
    }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}
