<div class="text-center">
  <a class="logo mb-3" href="#" >
    <img src="../../../../assets/images/Manage_Your_Food_Program_LOGO_FINAL.png" height="100px">
  </a>
</div>
<nb-auth-block>
  <h2 class="title">Sign Up</h2>
  <form (ngSubmit)="register()" #form="ngForm">

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger">
      <div><strong>Oh snap!</strong></div>
      <div *ngFor="let error of errors">{{ error }}</div>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success">
      <div><strong>Hooray!</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </nb-alert>

    <div class="form-group">
      <label for="input-name" class="sr-only">Full name</label>
      <input nbInput
             [(ngModel)]="user.fullName"
             #fullName="ngModel"
             id="input-name"
             name="fullName"
             placeholder="Full name"
             autofocus
             fullWidth
             [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.fullName.required')"
             [minlength]="getConfigValue('forms.validation.fullName.minLength')"
             [maxlength]="getConfigValue('forms.validation.fullName.maxLength')">
      <small class="form-text error" *ngIf="fullName.invalid && fullName.touched && fullName.errors?.required">
        Full name is required!
      </small>
      <small
        class="form-text error"
        *ngIf="fullName.invalid && fullName.touched && (fullName.errors?.minlength || fullName.errors?.maxlength)">
        Full name should contains
        from {{getConfigValue('forms.validation.fullName.minLength')}}
        to {{getConfigValue('forms.validation.fullName.maxLength')}}
        characters
      </small>
    </div>

    <div class="form-group">
      <label for="input-email" class="sr-only">Email address</label>
      <input nbInput
             [(ngModel)]="user.email"
             #email="ngModel"
             id="input-email"
             name="email"
             pattern=".+@.+..+"
             placeholder="Email address"
             fullWidth
             [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.email.required')">
      <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
        Email is required!
      </small>
      <small class="form-text error"
             *ngIf="email.invalid && email.touched && email.errors?.pattern">
        Email should be the real one!
      </small>
    </div>

    <div class="form-group">
      <label for="input-password" class="sr-only">Password</label>
      <input nbInput
             [(ngModel)]="user.password"
             #password="ngModel"
             type="password"
             id="input-password"
             name="password"
             placeholder="Password"
             fullWidth
             [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.password.required')"
             [minlength]="getConfigValue('forms.validation.password.minLength')"
             [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
        Password is required!
      </small>
      <small
        class="form-text error"
        *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
        Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </small>
    </div>

    <div class="form-group">
      <label for="input-re-password" class="sr-only">Repeat password</label>
      <input nbInput
             [(ngModel)]="user.confirmPassword"
             #rePass="ngModel"
             type="password"
             id="input-re-password"
             name="rePass"
             placeholder="Confirm Password"
             fullWidth
             [status]="email.dirty ? (email.invalid || password.value != rePass.value  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.password.required')">
      <small class="form-text error"
             *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
        Password confirmation is required!
      </small>
      <small
        class="form-text error"
        *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
        Password does not match the confirm password.
      </small>
    </div>

    <div class="form-group accept-group col-sm-12" *ngIf="getConfigValue('forms.register.terms')">
      <nb-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
        Agree to <a href="#" target="_blank"><strong>Terms & Conditions</strong></a>
      </nb-checkbox>
    </div>

    <button nbButton
            status="success"
            fullWidth
            [disabled]="submitted || !form.valid"
            [class.btn-pulse]="submitted">
      Register
    </button>
  </form>

  <div class="links">

    <ng-container *ngIf="socialLinks && socialLinks.length > 0">
      <small class="form-text">Or connect with:</small>

      <div class="socials">
        <ng-container *ngFor="let socialLink of socialLinks">
          <a *ngIf="socialLink.link"
             [routerLink]="socialLink.link"
             [attr.target]="socialLink.target"
             [attr.class]="socialLink.icon"
             [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
          <a *ngIf="socialLink.url"
             [attr.href]="socialLink.url"
             [attr.target]="socialLink.target"
             [attr.class]="socialLink.icon"
             [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
        </ng-container>
      </div>
    </ng-container>

    <small class="form-text">
      Already have an account? <a routerLink="../login"><strong>Sign in</strong></a>
    </small>
  </div>
</nb-auth-block>
